import {
  FaLinkedinIn,
  FaTwitter,
  FaInstagram,
  FaTelegramPlane,
  FaFacebook,
} from "react-icons/fa";
import openseaIcon from "../images/icon/opensea.svg";

const data = [
  {
    thumb: openseaIcon,
    url: "https://opensea.io/collection/luckypass",
  },
  {
    icon: <FaTwitter />,
    url: "https://twitter.com/LuckypassNFT",
  },
];

export default data;
