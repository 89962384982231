import { useModal } from "../../../../utils/ModalContext";
import Counter from "../../../../common/counter";
import Button from "../../../../common/button";
import BannerV1Wrapper from "./Banner.style";
import PassCard from "../../../../assets/images/nft/luckypass3.gif";
import { useEffect, useState } from "react";
import { totalMintCount } from "../../../../utils/web3mint";
import { isMetaMaskInstalled } from "../../../../config";
import GA4React from "ga-4-react";

const Banner = () => {
  const {
    walletModalHandle,
    metamaskModalHandle,
    mintModalHandle,
    connectWalletModalHanlde,
    account,
  } = useModal();
  const [remaining, setRemaining] = useState(0);

  const ga4react = new GA4React("G-V6HRKSN8J6");

  const eventTrack = (category, action, label) => {
    //console.log("GA event:", category, ":", action, ":", label);
    ga4react.event(category, action, label);
  }

  const handleWalletConnect = async () => {
    if (!isMetaMaskInstalled()) {
      metamaskModalHandle();
    } else {
      walletModalHandle();
    }
  };



  useEffect(() => {
    const calculateRemainingItems = async () => {
      let totaltMintedItems = await totalMintCount();
      //let totaltMintedItems = '10';
      console.log(totaltMintedItems);
      setRemaining(totaltMintedItems);
    };

    calculateRemainingItems();
  }, []);
  return (
    <BannerV1Wrapper id="home">
      <div className="container">
        <div className="row">
          <div className="col-lg-6">
            <div className="bithu_v1_baner_left">
              <h2>LuckyPass 🎟️ NFT Mint</h2>
              {remaining <= 999 || remaining != '' ? (
                <>
                  {account ? (

                    <h3>
                      <span className="count">
                        <Counter end={remaining} duration={remaining} />
                      </span>{" "}
                      / 1000 Minted
                    </h3>

                  ):( <span></span> )}
                  <div className="banner_buttons">
                    {account ? (
                      <Button
                        lg
                        variant="mint"
                        onClick={() => { mintModalHandle(); eventTrack('MintBtn', 'click', 'mint'); }}
                      >
                        {" "}
                        Mint now
                      </Button>
                    ) : (
                      <Button
                        lg
                        variant="mint"
                        onClick={() => { handleWalletConnect(); eventTrack('ConnectWallet', 'click', 'mint'); }}
                      >
                        {" "}
                        Connect Wallet
                      </Button>
                    )}

                    {/*<Button lg variant="outline">
                        Wishlist now
                    </Button>*/}
                  </div>
                  <div className="coin-info">
                    <span>Price 0.01 ETH + gas</span>
                    <span>
                      MINT IS LIVE{" "}
                      {/*<span className="highlighted">UNTIL 25 APR 04:00H</span>*/}
                    </span>
                    {/*<span>Presale : SOLDOUT</span>*/}
                  </div>
                </>
              ) : (
                <h3>Please connect wallet {remaining}</h3>
              )}
            </div>
          </div>
          <div className="col-lg-6">
            <div className="bithu_v1_baner_right">
              <div className="bithu_v1_baner_right_img_sect">
                <div className="bithu_v1_baner_right_img_bg">
                  <img src={PassCard} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </BannerV1Wrapper>
  );
};

export default Banner;
