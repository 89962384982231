import aboutCardIcon1 from "../../images/sneakpeek/cybers.gif"
import aboutCardIcon2 from "../../images/sneakpeek/hearts.gif"
import aboutCardIcon3 from "../../images/sneakpeek/stamps.gif"

 const data = {
    aboutDescription1: 'LuckyPass is a collection of 1000 passes that will morph into a lucky game. After the full minting, the system will auto-drop a hidden prize randomly on 298 auto-random holder wallets.',
    aboutDescription2: 'This is the first round of 4 collections; the first collection is the key for the next collections. If your wallet gets the lucky leaf after the collection has sold out, your wallet will get the drop.',
    aboutInfo: [
        {
            icon: aboutCardIcon1,
            title: "CyberPunk",
            text: "Unique 3D CyberPunks"
        },
        {
            icon: aboutCardIcon2,
            title: "Hearts",
            text: 'Exclusive hand draw hearts collection'
        },
        {
            icon: aboutCardIcon3,
            title: "World Stamps",
            text: 'Unique hand draw stamps'
        }     
    ]
}

export default data;