const data = [
  {
    title: "What is LuckyPass NFT?",
    text: "LuckyPass is an NFT collection of 1000 passes with an auto prize drop for 298 random holder wallets after 48 hours after sold out.",
  },
  {
    title: "How many collections?",
    text: "LuckyPass is the first collection of 4, each collection increase the number of drops at sold out",
  },
  {
    title: "Is there Twitter or discord?",
    text: "Yes, out twitter account is ",
    link: "https://twitter.com/LuckypassNFT",
    text2: " and After sold out of the collection we will activate discord account.",
  },
  {
    title: "Why is not promotion or WL for this collection?",
    text: "This is our first collection of 4, first come, first served.",
  },
  {
    title: "What is your contract address?",
    text: "The contract address is on Ethereum:",
    link: "https://etherscan.io/address/0xf3ce9b1f82c13f376ee01af437ab7a5dbe49325a",
  },
  {
    title: "Can I mint directly from the contract?",
    text: "Yes, the contract is already verified and you can mint directly from it",
  },
];

export default data;
