import nextdrop from "../../assets/images/partners/nextdrop-w.png";
import nftcalendar from "../../assets/images/partners/nftcalendar.png";
import nftiming from "../../assets/images/partners/NFTiming.png";

const data = [
  {
    thumb: nextdrop,
    url: "https://nextdrop.com/collections/?search=luckypass",
  },
  {
    thumb: nftcalendar,
    url: "https://nftcalendar.io/event/luckypass/",
  },
  {
    thumb: nftiming,
    url: "https://nftiming.com/nft/luckypass/",
  },
];

export default data;
