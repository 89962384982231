import AboutCardStyleWrapper from "./AboutCard.style";

const AboutCard = ({ title, icon, text }) => {
  return (
    <AboutCardStyleWrapper className="about_us_text_card">
      <img src={icon} width="400" alt="Collection"/>
      <h3>
        {title}
      </h3>
      <p>{text}</p>
    </AboutCardStyleWrapper>
  );
};

export default AboutCard;
