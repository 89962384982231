import SectionTitle from "../../../common/sectionTitle";
import data from "../../../assets/data/partnerData";
import PartnerStyledWrapper from "./Partner.style";
import GA4React from "ga-4-react";

const Partner = () => {

  const ga4react = new GA4React("G-V6HRKSN8J6");

  const eventTrack = (category, action, label) => {
    //console.log("GA event:", category, ":", action, ":", label);
    ga4react.event(category,action,label);
  }

  return (
    <PartnerStyledWrapper>
      <div className="our_partners_sect">
        <div className="container">
          <SectionTitle
            isCenter={true}
            subtitle="AS SEEN IN"
            className="text-center"
          />
          <div className="our_partnar_content">
            <ul>
              {data?.map((item, i) => (
                <li key={i}>
                  <a href={item.url} target='_blank' onClick={() => {eventTrack('Partner_'+item.url,'click','mint');}}>
                  <img src={item.thumb} alt="" />
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </PartnerStyledWrapper>
  );
};

export default Partner;
